export type TranslationObject = {
  [value in TranslationKeys]?: string;
};
export enum TranslationKeys {
  EN = 'en',
  SV = 'sv',
  NO = 'no',
  DE = 'de',
  DA = 'da',
  FI = 'fi'
  /* ES = 'es',
  FR = 'fr',
  NL = 'nl', */
}

export type Translation = 'en' | 'sv' | 'no' | 'de' | 'da' | 'fi';
